import React from "react"

import Page from "../../components/Page"
import Banner from "./HomeBanner"
import About from "./HomeAbout"
import ConcertHall from "./HomeConcertHall"
// import IgFeed from "./HomeIgFeed" 
import Cta from "./HomeCta"

export default () => (
  <Page title="Home">
    <Banner />
    <About />
    <ConcertHall />
    {/* <IgFeed /> */}
    <Cta />
  </Page>
)
