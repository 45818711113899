import React from "react";
import imgAbout from "../../images/cta-music-republicain.jpg";
import Image from "../../components/Image";

export default () => (
  <section className="about_part section_padding">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-md-6">
          <div className="about_part_text">
            <h1>
              <div className="brand">Music Republicain</div>
            </h1>
            <h2>Music School</h2>
            <p>
              Music Républicain offers music lessons in both private and group
              classes for various musical instruments. We have everything from
              chamber, band, digital music, early childhood, theory &amp;
              composition, and choir among others. <br />
              Realizing that every student has different learning style and
              objectives, lesson plans are tailored differently from one student
              to another. This customization is one of the main strengths of our
              highly trained music instructors. <br />
              Our doors are open to students of all ages as our philosophy is
              that you're never to young nor too old to cultivate and unleash
              your talents in music. That's why you'll see that our students are
              as young as 2 years old, and as senior as mid 80s.
            </p>
            <a href="/music-school" className="btn_1">
              explore our classes
            </a>
          </div>
        </div>
        <div className="col-md-6">
          <div className="about_part_img">
            <Image src={imgAbout} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);
