import React from "react";

export default () => (
  <div className="cta_part">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="cta_iner">
            <h3>Explore Your Inner Music Talent!</h3>
            <a href="https://wa.me/6281389113119" className="btn_1">
              Chat with Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
